import { Component, Prop, Vue } from 'vue-property-decorator'
import FormCrm from '@/components/CrmForm/Crm/Crm.vue'
import Crm from '@/Models/Crm'
import http from '@/services/http'
import Search from '@/components/Search/Search.vue'
import ModalDelete from '@/components/ModalDelete/ModalDelete.vue'
import * as Helpers from '@/helpers'
import menuModule from '@/store/menu'
import router from '@/router'
import Header from '@/components/Header/Header.vue'
import Animation from '@/components/Animation/index.vue'
@Component({
  components: {
    FormCrm,
    Search,
    ModalDelete,
    Header,
    Animation,
  },
})
export default class Dependent extends Vue {
  showList = true
  data: Array<Crm> = []
  objCrm: Crm = new Crm()
  member: Crm = new Crm()
  loading = false
  delete_modal_active = false
  searchVisibility = 'is-hidden'
  btn_search = ''
  currentPage = 1
  //accion que envia dependiendo la opcion
  action = 'addDependent'
  //th de la tabla
  header: Array<any> = [
    {
      field: 'member_number',
      label: '# Miembro',
      centered: true,
    },
    {
      field: 'parent_client',
      label: 'Pariente id',
      centered: true,
    },
    {
      field: 'client_name',
      label: 'Cliente',
      centered: true,
    },
    {
      field: 'migratory_status',
      label: 'Estado',
      centered: true,
    },
    {
      field: 'client_email',
      label: 'Email',
      centered: true,
    },
    {
      field: 'client_email_alternative',
      label: 'Email alternativo',
      centered: true,
    },
    {
      field: 'phone',
      label: 'Teléfono',
      centered: true,
    },
  ]
  mounted() {
    this.getDependent()
    ;(this.$refs.header as any).isViewPrincipal = false
  }
  async getDependent() {
    this.loading = true
    const id = menuModule.id_param
    if (id == null) throw router.push({ name: 'CCSS' }).catch(console.log)
    try {
      const res = await http.get(`api/crm/crm_client_file_dependent/${id}/`)
      if (!res.data) throw new Error(res.data)
      console.log(res.data)
      if (res.data.dependents != 'Este miembro no tiene familiares asociados') {
        this.data = res.data.dependents.map((obj: any) => ({
          ...obj,
          birth_date: Helpers.dateParse(obj.birth_date),
          date_expire_identification: Helpers.dateParse(
            obj.date_expire_identification,
          ),
          phone: Helpers.phoneFormat(obj.phone),
          house_phone: Helpers.phoneFormat(obj.home_phone),
          work_phone: Helpers.phoneFormat(obj.work_phone),
          date_approbation: Helpers.dateParse(obj.date_approbation),
          date_start_membership: Helpers.dateParse(obj.date_start_membership),
          date_expire_membership: Helpers.dateParse(obj.date_expire_membership),
          file: obj.file,
          upload:
            obj.file != null
              ? new File(
                  ['foo'],
                  obj.file.substring(obj.file.lastIndexOf('/') + 1),
                )
              : null,
          parent_client: obj.parent_client.id,
        }))
      }
      this.member = res.data.member
    } catch (error: any) {
      console.log(error.response.data)
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
    this.loading = false
  }

  editObj(props: any) {
    this.objCrm = props
    this.showList = false
    this.action = 'edit-dependent'
  }
  async deleteObj() {
    try {
      await http.delete(`api/crm/crm_client_file_dependent/${this.objCrm.id}/`)
      this.data = this.data.filter((a: Crm) => a.id !== this.objCrm.id)
      this.delete_modal_active = false
      this.getDependent()
    } catch (error) {
      console.log(error)
      //toast.error("An error has occurred please try again.")
    }
  }
  deleteConfirm(props: any) {
    this.delete_modal_active = true
    this.objCrm = props
  }
  closeModal() {
    this.objCrm = new Crm()
    this.delete_modal_active = false
  }
  openForm() {
    this.showList = false
  }
  async cleanForm() {
    ;(this.$refs.header as any).showList = true
    this.objCrm = new Crm()
    this.showList = true
    this.action = 'addDependent'
    await this.getDependent()
  }
  isData(data: any) {
    this.data = data
  }
}
